import LoginManager from './LoginManager';

const script = document.currentScript;

if (script?.hasAttribute('util')) {
} else if (script?.hasAttribute('allowUnauthorized') || script?.hasAttribute('allowunauthorized')) {
  if ((LoginManager.hasCookie('refreshToken') && !LoginManager.hasCookie('token')) || (LoginManager.hasCookie('refreshToken') && LoginManager.tokenExpired(LoginManager.parseJwt(LoginManager.getCookie('token')!))))
    if (!LoginManager.isUpdating)
      LoginManager.refreshToken();
} else if (script?.hasAttribute('alwaysActive') || script?.hasAttribute('alwaysactive')) {
  LoginManager.updateToken().then(() => {});
} else {
  if (LoginManager.hasCookie('refreshToken')) {
    LoginManager.validateToken();
  } else {
    window.location.href = 'https://login.' + LoginManager.domain + '?redirect=' + window.location.href;
  }
}
